;(function () {
  if (!String.prototype.at) {
    Object.defineProperty(String.prototype, 'at', {
      value: function (index) {
        // Convert the index to a positive integer
        index = Math.trunc(index) || 0

        // Handle negative indices
        if (index < 0) {
          index += this.length
          if (index < 0) {
            index = 0
          }
        }

        // Return the character at the specified index
        return this.charAt(index)
      },
      writable: true,
      configurable: true,
    })
  }
})()
