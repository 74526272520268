import FontFaceObserver from '../fontfaceobserver'

function onFontLoadSuccess() {
  document.documentElement.className += ' fonts-loaded'
}

function onFontLoadError() {
  console.log('Aktiv Grotesk failed to load')
}

const font = new FontFaceObserver('Aktiv Grotesk')
font
  .load()
  .then(onFontLoadSuccess)
  .catch(onFontLoadError)

const fontBold = new FontFaceObserver('Aktiv Grotesk', { weight: 700 })
fontBold.load().catch(onFontLoadError)
