// Source:
// https://codepen.io/gapcode/pen/vEJNZN

function detectIE() {
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  const trident = ua.indexOf('Trident/')
  if (trident > 0) {
    const rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  const edge = ua.indexOf('Edge/')
  if (edge > 0) {
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  }

  return false
}

const html = document.documentElement
const version = detectIE()

if (version === false) {
  html.classList.add('not-ie')
} else if (version >= 12) {
  html.classList.add('edge')
  html.classList.add('edge-' + version)
} else {
  html.classList.add('ie')
  html.classList.add('ie-' + version)
}
